import { Row, Col } from 'antd'
import styled from 'styled-components'

import Ecosystem from './Ecosystem'
import Highlight from './Highlight'
import Tabs from './Tabs'

// import SignInButtons from './SignInButtons'
import SuperHero from './SuperHero'

const LimitedCol = styled(Col)`
  max-width: 1600px;
  margin: auto;
`

export default function Home () {
  return (

    <Row gutter={[0, 72]}>
      <LimitedCol span={22} offset={1}>
        <Ecosystem />
      </LimitedCol>

      {/* <LimitedCol span={22} offset={1}>
        <SignInButtons />
      </LimitedCol> */}

      <Col span={24}>
        <Highlight />
      </Col>

      <LimitedCol span={22} offset={1}>
        <Tabs type='feature' />
      </LimitedCol>

      <LimitedCol span={22} offset={1}>
        <SuperHero />
      </LimitedCol>

    </Row>
  )
}

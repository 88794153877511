import { useEffect } from 'react'
import mermaid from 'mermaid'

mermaid.initialize({
  startOnLoad: true,
  theme: 'default',
  securityLevel: 'loose'
})

export default function Mermaid ({ code }): React.ReactElement {
  useEffect(() => {
    const container = document.getElementById('ven-mermaid')
    if (container === null) {
      return
    }

    container.removeAttribute('data-processed')
    container.innerHTML = code
    mermaid.contentLoaded()
  }, [code])

  return <div className='mermaid' id='ven-mermaid' />
}

import { Typography, Carousel, Tag, Image } from 'antd'
import HomeFeature from './Feature'

const { Paragraph, Title } = Typography

const tabList = [

  {
    children: (
      <HomeFeature
        title={<Title level={2} style={{ fontWeight: 200, marginTop: 0 }}>Write once, read forever</Title>}
        noTitle
        suffix={<><Tag color='success'>free scalable reads</Tag> <Tag color='success'>predictable low cost writing</Tag></>}
        bullets={
          <>
            <Paragraph type='secondary'>
              <ul>
                <li>Reading data is free and can be done directly by users, eliminating the need for a custom backend</li>
                <li>Writing data is possible from any backend application that supports HTTP requests</li>
                <li>Transaction costs can be calculated in advance and remain constant</li>
              </ul>
            </Paragraph>
          </>
        }
        teaser={<Image src={require('./assets/BaaS.webp')} preview={false} style={{ maxWidth: 540 }} width='100%' alt='vechain.energy' />}
      />
    )
  },
  {
    children: (
      <HomeFeature
        title={<Title level={2} style={{ fontWeight: 200, marginTop: 0 }}>dApps that are Easy to Use</Title>}
        noTitle
        suffix={<><Tag color='success'>free scalable reads</Tag> <Tag color='success'>predictable low cost hosting</Tag> <Tag color='success'>gasless transactions</Tag> <Tag color='success'>low user barrier</Tag></>}
        bullets={
          <>
            <Paragraph type='secondary'>
              <ul>
                <li>Provide gas-free interactions to lower barriers and make dApps more accessible</li>
                <li>Users don't need tokens or knowledge of transaction costs</li>
                <li>Fee delegation allows developers to pay for user fees, similar to hosting costs</li>
                <li>Pay per write cost calculation makes it easy to understand and predict expenses</li>
              </ul>
            </Paragraph>
          </>
        }
        teaser={
          <div
            style={{ padding: '16px 0 16px 0' }}
          >
            <Image
              src='https://mermaid.ink/svg/pako:eNqFU8GO0zAQ_RXLVZVLi7rLUmiQeugiOCEhFTj5MrWdrNVkHOwJ2hLl37Eb06Ytq80t896bN5m86bi0SvOcT6edQUM56zJ60rXOcpYpcPtsxgQ_Vh63W8Hz8PbGE5BWBkoH9Rwtaea0JNaxwlRVziZSvt3d3X9kveAzNrT7Cc7ArtI-9O1CTT_To62sizaT4vgEpwzDMN9fwGowuNmXx-rgMChIX1VZ3_fTqUCvf7Uapf40TCqQhacBR0aaBpDYD6_dbfULePYNDv-DNpWVe_kUBhE4oLHFfL0-aXLmNSrWojclasXIAXqQZGwSnJjzS9lvqIwKa31FEf2SR3QAap1m0mJhXG2wZGUgN3CoNdJowJMQlPpH0W7Ugeyt76Uycl_grNfnvQRiu6sN3VLPnHPTNPhr5PF2210gh7DFr5DWkxfIZ7zWLsRDhRx3sUFK7BDXmOIQxFH9FMZI6AZHwWP2Ntap8OOPusli8bBaLZI0MlICE7xcLkfYRXIT4_79w-LdakQqLNJnqE11GBhxjuoK35o_afC7D83zCKSr7uEsBI9gL7APO4CW7PaAkufkWj3jbRPzlMLP8wIqH6rhbsm6r8PRH2-__wv6D1Ci'
              style={{ maxWidth: 540, maxHeight: 440 }}
              width='100%'
              alt='vechain.energy'
            />
          </div>
        }
      />
    )
  },
  {
    children: (
      <HomeFeature
        title={<Title level={2} style={{ fontWeight: 200, marginTop: 0 }}>Oracles – API Requests on the blockchain</Title>}
        noTitle
        suffix={<><Tag color='success'>Webhooks</Tag> <Tag color='success'>REST-API</Tag></>}
        bullets={
          <>
            <Paragraph type='secondary'>
              <ul>
                <li>Send webhooks from a contract to access external blockchain data</li>
                <li>Use the transaction API to respond to webhooks</li>
                <li>Connect smart contracts to the internet and even offline sources</li>
              </ul>
            </Paragraph>
          </>
        }
        teaser={
          <div
            style={{ padding: '16px 0 16px 0' }}
          >
            {/* https://mermaid.live/edit#pako:eNp1UjtPwzAQ_isnoygD7QBsHipBGWBAVDSCJcs1uTZWk3OwHR6K8t-5xGVoq97m73Vnn3tV2JKUVknSGzZBQ5-GihpKNaQlun06gwi8ozO4qckL0wtGP2Fpa-tG4dV2qlHLEpdd4Bo0_LDfTWhR3G1ublMYhiFJcvb02REX9Ghw57DJGaRadMEUpkUOsG7kAEvLwWERzvkP2lTW7s-JzCF7sRjLcL96Phe8SmBNET_uMl8srg-5GqgxAeiLxPFtQjVmwds4tT9Mc1DOR1fM1PCUZStYdb46lkZ6VJ5Mp8GRby2XsUeJAaPjRDdaj2fVcifvJwcYBgRBGf3kyFnNVENO3r-UVfc5DwJgF-z6lwulg-toprpWzP8LUHqLtReUShOse4mfZPorwx-qOr56 */}
            <Image
              src='https://mermaid.ink/svg/pako:eNp1UjtPwzAQ_isnoygD7QBsHipBGWBAVDSCJcs1uTZWk3OwHR6K8t-5xGVoq97m73Vnn3tV2JKUVknSGzZBQ5-GihpKNaQlun06gwi8ozO4qckL0wtGP2Fpa-tG4dV2qlHLEpdd4Bo0_LDfTWhR3G1ublMYhiFJcvb02REX9Ghw57DJGaRadMEUpkUOsG7kAEvLwWERzvkP2lTW7s-JzCF7sRjLcL96Phe8SmBNET_uMl8srg-5GqgxAeiLxPFtQjVmwds4tT9Mc1DOR1fM1PCUZStYdb46lkZ6VJ5Mp8GRby2XsUeJAaPjRDdaj2fVcifvJwcYBgRBGf3kyFnNVENO3r-UVfc5DwJgF-z6lwulg-toprpWzP8LUHqLtReUShOse4mfZPorwx-qOr56'
              style={{ maxWidth: 540, maxHeight: 440 }}
              width='100%'
              alt='vechain.energy'
            />
          </div>
        }
      />
    )
  },
  {
    children: (
      <HomeFeature
        title={<Title level={2} style={{ fontWeight: 200, marginTop: 0 }}>Scheduled Transactions</Title>}
        noTitle
        suffix={<><Tag color='success'>Schedulers</Tag> <Tag color='success'>REST-API</Tag></>}
        bullets={
          <>
            <Paragraph type='secondary'>
              <ul>
                <li>Automate token swaps and payouts at regular intervals</li>
                <li>Use scheduled transactions to perform conversions and share payments with shareholders</li>
              </ul>
            </Paragraph>
          </>
        }
        teaser={
          <div
            style={{ padding: '16px 0 16px 0' }}
          >
            <Image
              src='https://mermaid.ink/svg/pako:eNp1UsFqwzAM_RXhEnJJC-tuHuzQ9rpTynbxxbWV1sSxi6OsHSH_PtcOpAymk_Wk9yRLGpnyGhlnRTEaZ4jDWNIFOyw5lFqGtqxAsITs61owHr1NT5JQG3kOsls7TwgBFcEIjbGWw0qp19PL9g0mwSrIcp8yGHmy2EfdMWJ4p723PjzKrJpksVLpYjPHf2KdNG7XnhOaK2QG4R8UpmkqCuFSm4e5ze-tcBBNm0evxjs47jJSqwvqwSKs1-9Q3-R1huMrQQfTUzCngTAHFj8zZrpwC4_D0bfontQWEn8WIA9f0lqkPqflYZrzhcA3T9Iw200agsYHcHFIgHdUw-MvOQGdTgLCsYp1GOLAdNzsOEVXDuTrH6cYpzBgxYarXobDeCNtH9G4VPLhI19EOozpF2L0sN0'
              width='100%'
              style={{ maxWidth: 540, maxHeight: 440 }}
              alt='vechain.energy'
            />
          </div>
        }
      />
    )
  },
  {
    children: (
      <HomeFeature
        title={<Title level={2} style={{ fontWeight: 200, marginTop: 0 }}>Serverless Indexer</Title>}
        noTitle
        suffix={<><Tag color='success'>Webhooks</Tag></>}
        bullets={
          <>
            <Paragraph type='secondary'>
              <ul>
                <li>Subscribe to blockchain events from contracts to receive event information on a serverless function</li>
                <li>Use serverless technology to process and index the information</li>
                <li>Leverage the scalability and flexibility of a serverless architecture to handle varying workloads and events in real-time</li>
                <li>Save on operational costs by only paying for the specific resources and compute used for each event, rather than maintaining a dedicated server infrastructure</li>
              </ul>
            </Paragraph>
          </>
        }
        teaser={<Image src={require('./assets/BaaS.webp')} preview={false} style={{ maxWidth: 540 }} width='100%' alt='vechain.energy' />}
      />
    )
  }
]

export default function UseCases () {
  return (
    <>
      <Carousel
        autoplay
        autoplaySpeed={30 * 1000}
        dotPosition='bottom'
        dots={{ className: 'ven-carousel-dots-home' }}
      >
        {tabList.map((item, index) =>
          <div key={index}>
            {item.children}
          </div>
        )}
      </Carousel>
    </>
  )
}

/* eslint @typescript-eslint/no-var-requires: "off" */
import { Typography, Row, Col, Grid, Button } from 'antd'
import styled from 'styled-components'
import { CodeSandboxOutlined as IconSandbox, WechatOutlined as IconDiscord } from '@ant-design/icons'
const { useBreakpoint } = Grid
const { Title, Paragraph } = Typography

const TitleHighlight = styled.span`
  color: #353395;
  font-size: inherit;
`
const TitleHighlight2 = styled.span`
  color: #FF6D3D;
  font-size: inherit;
`

const TextBlock = {
  Energy: () => {
    const screens = useBreakpoint()

    return (
      <>
        <Title level={2}><TitleHighlight>vechain</TitleHighlight><TitleHighlight2>.energy</TitleHighlight2></Title>
        <Paragraph>
          Our goal is to bridge the gap between web2 and web3, and give developers the power to use blockchain technology just like any other online service. Our services include:
        </Paragraph>
        <Paragraph>
          <ul>
            <li>Gasless transactions with a Fee Delegation Service</li>
            <li>API layers that make blockchain technology easy to use</li>
            <li>Services that enable configuration in existing applications</li>
            <li>Templates for instant deployment of contracts</li>
            <li>Helpers that make management accessible to everyone, regardless of technical expertise</li>
          </ul>
        </Paragraph>

        <Button type='link' block icon={<IconSandbox />} href='https://sandbox.vechain.energy' target='_blank' rel='noreferrer'>Experiment {screens.sm ? 'with everything' : ''} in our public Sandbox</Button>
      </>
    )
  },

  Features: () => (
    <>
      <Title level={2}><TitleHighlight2>Blockchain</TitleHighlight2> <TitleHighlight>Everywhere</TitleHighlight></Title>
      <Paragraph>
        Implement Blockchain as a Service into any application:
      </Paragraph>
      <Paragraph>
        <ul>
          <li>Use pre-made template contracts without technical skills</li>
          <li>View and access data stored on the blockchain</li>
          <li>Interact with smart contracts on the blockchain</li>
          <li>Automate interactions using schedulers</li>
          <li>Get notifications with HTTP-webhooks</li>
          <li>Use blockchain technology without exposing it to your users</li>
          <li>Support for transaction pricing in USD and EUR, without the need to handle tokens <sup style={{ opacity: 0.25 }}>in Q2/2023</sup></li>
        </ul>
      </Paragraph>
    </>
  ),

  Closing: () => {
    return (
      <>
        <Title level={2}><TitleHighlight>Developer</TitleHighlight> <TitleHighlight2>Superhero</TitleHighlight2></Title>
        <Paragraph>
          We want to support the developer superhero in you. That's why we have a developer community, a knowledge base, free sandbox access, and even free sponsorships.
        </Paragraph>
        <Paragraph>
          If you have any questions, join our Discord and get to know us.
        </Paragraph>

        <Button type='link' block icon={<IconDiscord />} href='https://discord.gg/dhVCVNbHRT' rel='noreferrer noopener'>Go to Developer Community</Button>
      </>
    )
  }
}

export default function HomeFeatures (): React.ReactElement {
  const screens = useBreakpoint()

  const isDesktop = screens.lg

  if (!isDesktop) {
    const mobileImage = require('./mobile.webp')
    return (
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <img alt='' src={mobileImage} style={{ objectFit: 'none', objectPosition: '100% 0%', height: '320px', width: '100%', marginBottom: -3 }} />
        </Col>
        <Col span={22} style={{ textAlign: 'left' }}>
          <TextBlock.Energy />
        </Col>
        <Col span={2}>
          <img alt='' src={mobileImage} style={{ objectFit: 'none', objectPosition: '100% 11%', height: '100%', width: '100%' }} />
        </Col>

        <Col span={24}>
          <img alt='' src={mobileImage} style={{ objectFit: 'none', objectPosition: '100% 45%', height: '450px', width: '100%', marginBottom: -3 }} />
        </Col>
        <Col span={22} style={{ textAlign: 'left' }}>
          <TextBlock.Features />
        </Col>
        <Col span={2}>
          <img alt='' src={mobileImage} style={{ objectFit: 'none', objectPosition: '100% 74%', height: '100%', width: '100%' }} />
        </Col>

        <Col span={24}>
          <img alt='' src={mobileImage} style={{ objectFit: 'none', objectPosition: '100% 100%', height: '300px', width: '100%' }} />
        </Col>
        <Col span={22} style={{ textAlign: 'left' }}>
          <TextBlock.Closing />
        </Col>
        <Col span={2} />

      </Row>
    )
  }

  const desktopImage = require('./desktop.webp')
  return (
    <Row
      gutter={[0, 0]}
      style={{
        backgroundImage: `url(${desktopImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 0%'
      }}
      align='middle'
    >
      <Col xs={{ span: 12, offset: 12 }} md={{ span: 10, offset: 14 }} xl={{ span: 9, offset: 15 }} style={{ textAlign: 'left', height: screens.lg ? 400 : 300, paddingTop: 0 }}>
        <TextBlock.Energy />
      </Col>

      <Col span={8} offset={1} style={{ textAlign: 'left', height: screens.lg ? 500 : screens.md ? 400 : 350, paddingTop: 0 }}>
        <TextBlock.Features />
      </Col>

      <Col span={8} offset={16} style={{ textAlign: 'left', height: 300, paddingTop: 0 }}>
        <TextBlock.Closing />

      </Col>

    </Row>

  )
}

import { theme } from 'antd'
import styled from 'styled-components'

const { darkAlgorithm } = theme

export const dark = {
  algorithm: [darkAlgorithm],
  token: {
    colorPrimary: '#1f1f1f',
    colorLink: '#ffffff',
    colorLinkHover: 'rgb(241,84,33,0.5)',
    colorLinkActive: 'rgb(241,84,33,0.5)',
    colorWarning: '#F68A1E',
    colorError: '#ED1D1D',
    borderRadius: 4
  }
}

export const light = {
  token: {
    colorPrimary: 'rgb(241,84,33)',
    colorPrimaryActive: '#cc3b12',
    colorLink: 'rgb(241,84,33)',
    colorLinkHover: 'rgb(241,84,33,0.5)',
    colorLinkActive: 'rgb(241,84,33,0.5)',
    colorWarning: '#F68A1E',
    colorError: '#ED1D1D',
    borderRadius: 4
  }
}

export const Styles = styled.div`

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-upload.ant-upload-drag {
  height: auto;
}


.ant-layout-header {
  padding-inline: 0 !important;
}


.inner-shadow {
  box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.75) inset;
}

.outer-shadow {
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .ven-events-list .ant-list-item .ant-list-item-action {
    margin-left: 0;
    margin-inline-start: 0;
  }

  .ven-events-list .ant-list-item .ant-list-item-meta-title li * {
    font-size: 0.75rem;
  }

  .ven-events-list .ant-list-item .ant-list-item-action li * {
    font-size: 0.75rem;
  }

  .ven-events-list .ant-col .ant-list-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ven-events-list .ant-list-footer {
    padding-block: 0;
  }

  .ven-events-list .ant-list-footer .ant-row * {
    font-size: 0.75rem;
  }
}

.ant-image-preview-img {
  background-color: #fff;
}


/* fix mobile tab scrolling hiding first entries */
.ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right {
  justify-content: inherit !important;
}

.ven-benefits .ant-tabs-nav-list .ant-tabs-tab {
  padding-left: 0 !important;
}

.ven-benefits .ant-tabs-content-holder {
  border: 0;
}

.ven-benefits .ant-tabs-tab-btn>span {
  color: #fff;
  transition: 0.25s color linear;
}

.ven-benefits .ant-tabs-tab-active .ant-tabs-tab-btn>span {
  color: #F15421;
}

.ven-benefits .ant-tabs-content.ant-tabs-content-left {
  margin-top: 4px;
}


.ven-terminal>pre {
  background: transparent !important;
}

.ven-carousel-dots-home li button {
  margin-top: 64px;
  background-color: #F15421 !important;
}

.ant-image-preview-img {
  padding: 32px 0 32px 0;
  background: linear-gradient(30deg, rgb(16, 18, 96) 0%, rgb(8, 87, 162) 70%);
}

.borderless .ant-table-tbody>tr>td {
  border: none;
  border-bottom: none !important;
}

.borderless .ant-table-thead>tr>th {
  background: none;
}

.color-error {
  color: #d00;
}

.ven-project-stats>.ant-collapse-item>.ant-collapse-header {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.ven-project-stats>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  /* padding-inline-start: 0 !important; */
}

.ant-layout-header {
  z-index: 0;
}

.ant-layout-sider {
  z-index: 1;
}

.layout-content {
  border-top: 8px solid #f1f1f1;
  border-top-left-radius: 8px;
  background-color: #f1f1f1;
  padding-top: 32px;
}

.layout-sider {
  background-color: #1f1f1f;
}

.ven-layout-full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
  background-color: #1f1f1f !important;
}
`

export const MenuStyles = styled.div`
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}

.ant-menu-item.ant-menu-item-selected, .ant-menu-item.ant-menu-item-active {
  background-color: rgb(241, 84, 33);
}

.ant-menu-submenu-title:hover *,
.ant-menu-item-active *,
.ant-menu-item.ant-menu-item-selected * {
  color: rgb(255, 255, 255);
  transition: 0.25s all linear;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-title-content {
  margin-right: 8px;
}


.ant-layout-sider,
.ant-layout-sider-trigger,
.ant-menu,
.ant-layout-header,
.ant-menu-submenu-popup {
  background-color: #1f1f1f !important;
}

.ant-menu-item-group-title {
  color: #636363;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.ant-layout-header {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}
`

export const Colors = {
  charts: {
    default: '#52ABFF',
    error: '#FF4646'
  }
}

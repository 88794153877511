import { Image, Typography, Row, Col } from 'antd'
/* eslint @typescript-eslint/no-var-requires: "off" */
const LoadingGif = require('../assets/Loading.gif')
const { Text } = Typography

export default function LogoLoading ({ size = 96, message, ...props }) {
  if (!message) {
    return <Image src={LoadingGif} width={size} height={size} preview={false} {...props} />
  }

  return (
    <Row gutter={[16, 16]} {...props}>
      <Col span={24} align='center'>
        <Image src={LoadingGif} width={size} height={size} preview={false} /><br />
      </Col>
      <Col span={24} align='center'>
        <Text type='secondary'>{message}</Text>
      </Col>
    </Row>
  )
}

import { Typography, Row, Col, Grid, Button } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Logo from '../../common/Logo/Vertical.js'
import { TwitterOutlined as IconTwitter, MediumOutlined as IconMedium, WechatOutlined as IconDiscord, UpOutlined as IconToTop } from '@ant-design/icons'

const { useBreakpoint } = Grid
const { Text, Link: LinkExt } = Typography

const MenuHeadline = styled(Text)`
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 1rem;
`

const MenuItem = styled(Link)`
  font-weight: 300;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 1rem;
`

const MenuItemExt = styled(LinkExt)`
  font-weight: 300;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 1rem;
`

const IconLink = styled(Button)`
  font-weight: 300;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: transparent;
  border-color: #fff;
`

const Styles = styled.div`
  * {
    color: #fff !important;
    transition: all 0.3s;
  }

  a:hover, a:hover *, button:hover * {
    color: #F15421 !important;
  }
`

export default function HomeFooter (): React.ReactElement {
  const screens = useBreakpoint()

  const handleTopScroll = (): void => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  return (
    <Row
      gutter={0}
      align='middle'
      style={{
        backgroundImage: 'linear-gradient(30deg, #101260 0%, #0857A2 70%)',
        minHeight: screens.lg ? 720 : 'auto'
      }}
    >
      <Col
        span={24}
        style={{
          backgroundImage: screens.lg ? 'url(/dots.svg)' : '',
          backgroundPosition: '50px -80px',
          backgroundRepeat: 'no-repeat',
          minHeight: screens.lg ? 720 : 'auto'
        }}

      >

        <div style={{
          backgroundImage: 'linear-gradient(25deg, transparent 85%, #fff 0%, #fff 0%)',
          height: screens.lg ? 220 : 'fit-content'
        }}
        >
          <div
            style={{
              height: screens.lg ? 240 : 80,
              paddingRight: 'calc(15%/4)',
              paddingTop: screens.lg ? 24 : 8,
              textAlign: 'right'
            }}
          >
            <Button shape='circle' type='primary' size='large' icon={<IconToTop />} onClick={handleTopScroll} />
          </div>
          <Styles>
            <Row gutter={[0, 64]}>

              <Col xs={0} lg={{ span: 5, offset: 1 }}>
                <Logo width={200} style={{ marginLeft: 50 }} />
              </Col>

              <Col xs={{ span: 10, offset: 2 }} md={{ span: 7, offset: 1 }} lg={{ span: 5, offset: 2 }}>
                <MenuHeadline>Home</MenuHeadline>
                {/* <MenuItemExt href='https://vechain.energy'>MainNet</MenuItemExt>
                <MenuItemExt href='https://testnet.vechain.energy'>TestNet</MenuItemExt> */}
                <MenuItemExt href='https://sandbox.vechain.energy' target='_blank' rel='noreferrer'>Sandbox</MenuItemExt>
                <MenuItemExt href='https://learn.vechain.energy' target='_blank' rel='noreferrer'>Getting Started</MenuItemExt>
              </Col>

              <Col xs={{ span: 10, offset: 0 }} md={7} lg={5}>
                <MenuHeadline>About</MenuHeadline>
                <MenuItem to='/privacy'>Privacy Policy</MenuItem>
                <MenuItem to='/imprint'>Imprint</MenuItem>
              </Col>

              <Col xs={0} md={7} lg={5}>
                <MenuHeadline>vechain.energy</MenuHeadline>
                <MenuItemExt href='https://status.vechain.energy/' target='_blank' rel='noreferrer'>System Status</MenuItemExt>
                <MenuItemExt href='https://nodes.status.vechain.energy/' target='_blank' rel='noreferrer'>Public Nodes Status</MenuItemExt>
                <MenuItemExt href='https://headwayapp.co/vechain-energy-changelog' target='_blank' rel='noreferrer'>Changelog</MenuItemExt>
              </Col>

              <Col xs={24} lg={{ span: 15, offset: 8 }} style={{ textAlign: screens.lg ? 'left' : 'center' }}>
                <IconLink href='https://discord.gg/dhVCVNbHRT' target='_blank' rel='noreferrer' size='large' shape='circle' icon={<IconDiscord />} />
                <IconLink href='https://twitter.com/VechainEnergy' target='_blank' rel='noreferrer' size='large' shape='circle' icon={<IconTwitter />} />
                <IconLink href='https://blog.vechain.energy' target='_blank' rel='noreferrer' size='large' shape='circle' icon={<IconMedium />} />
              </Col>

              <Col xs={24} lg={{ span: 15, offset: 8 }} style={{ textAlign: screens.lg ? 'left' : 'center' }}>
                <a target='_blank' href='https://betteruptime.com/' rel='noreferrer'><img style={{ width: 130, height: 52, marginLeft: '1rem' }} alt='Better Uptime Website Monitoring' src='https://betteruptime.com/assets/static_assets/badges/light.png' /></a>
              </Col>

              <Col span={24} />

            </Row>
          </Styles>
        </div>

      </Col>

    </Row>

  )
}

import { Row, Col, Typography } from 'antd'

const config = {
  oddNumber: true
}

export default function HomeFeature ({ noTitle, title, subtitle, suffix, bullets, teaser }) {
  const [left, right] = config.oddNumber ? [bullets, teaser] : [teaser, bullets]
  // config.oddNumber = !config.oddNumber

  return (
    <Row gutter={[16, 16]}>
      {title && <Col span={24}><Typography.Title level={4} style={{ margin: 0 }}>{title}</Typography.Title></Col>}
      {subtitle && <Col span={24}><Typography.Text strong>{subtitle}</Typography.Text></Col>}
      <Col xs={24} md={12} align='left'>
        {left}
      </Col>
      <Col xs={24} md={12} align='center' style={{ paddingTop: !noTitle && !title && !subtitle ? 32 : 0 }}>
        {right}
      </Col>
      {suffix && <Col xs={24} md={{ span: 12, offset: 12 }} align='center'><Typography.Text strong>{suffix}</Typography.Text></Col>}
    </Row>
  )
}

import { useState, useEffect, lazy, Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import Home from './pages/Home'
import HeaderBig from './Layout/HeaderBig'
import FooterBig from './Layout/FooterBig'
import LoadingLogo from './common/LoadingLogo'

import { Row, Col, Drawer } from 'antd'
import { ProvideVexchange } from './hooks/useVexchange'
import ScrollToTop from './common/ScrollToTop'

const Menu = lazy(() => import('./Layout/Menu'))
const Docs = lazy(() => import('./pages/Docs'))
const DocsApi = lazy(() => import('./pages/Docs/Api'))
const DocsSnippets = lazy(() => import('./pages/Docs/Snippets'))
const LimitsPricing = lazy(() => import('./pages/Docs/LimitsPricing'))
const IndexerAbout = lazy(() => import('./pages/Indexer/About'))
const IndexerExamples = lazy(() => import('./pages/Indexer/Examples'))
const WebhooksAbout = lazy(() => import('./pages/Sponsorships/Webhooks/About'))
const VthoContracts = lazy(() => import('./pages/Helper/Vtho/Contracts'))
const VthoCalculator = lazy(() => import('./pages/Helper/Vtho/Calculator'))
const HelperWizard = lazy(() => import('./pages/Helper/Wizard'))
const Imprint = lazy(() => import('./pages/Imprint'))
const Privacy = lazy(() => import('./pages/Privacy'))
const Helper = lazy(() => import('./pages/Helper'))
const Inspect = lazy(() => import('./pages/Inspect'))
const SignIn = lazy(() => import('./pages/SignIn'))

const apolloCache = new InMemoryCache()

export default function AppLoggdOut () {
  const [displayDrawer, setDisplayDrawer] = useState(false)
  const showDrawer = () => setDisplayDrawer(true)
  const hideDrawer = () => setDisplayDrawer(false)

  const location = useLocation()

  const graphClient = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_BACKEND_URI,
    cache: apolloCache
  })

  useEffect(() => {
    if (displayDrawer) {
      document.getElementById('app').style.filter = 'blur(5px)'
    } else {
      document.getElementById('app').style.filter = 'none'
    }
  }, [displayDrawer])
  useEffect(() => hideDrawer(), [location])

  return (
    <ApolloProvider client={graphClient}>
      <Row gutter={[0, 64]}>
        <Col span={24}>
          <HeaderBig onShowDrawer={showDrawer} />
        </Col>

        <Col span={24}>
          <Suspense fallback={<WrapContent><LoadingLogo message='Loading Application …' style={{ marginTop: 64 }} /></WrapContent>}>
            <ScrollToTop />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/signin' element={<WrapContent><SignIn /></WrapContent>} />
              <Route path='/docs' element={<WrapContent><DocsSnippets /></WrapContent>} />
              <Route path='/docs/pricing' element={<WrapContent><LimitsPricing /></WrapContent>} />
              <Route path='/docs/webhooks' element={<WrapContent><WebhooksAbout /></WrapContent>} />
              <Route path='/docs/indexer/about' element={<WrapContent block><IndexerAbout /></WrapContent>} />
              <Route path='/docs/indexer/examples' element={<WrapContent block><IndexerExamples /></WrapContent>} />
              <Route path='/docs/api/:page' element={<WrapContent><DocsApi /></WrapContent>} />
              <Route path='/docs/:page' element={<WrapContent><Docs /></WrapContent>} />
              <Route path='/helper' element={<WrapContent><Helper /></WrapContent>} />
              <Route path='/helper/vtho/contracts' element={<WrapContentWithPrice><VthoContracts /></WrapContentWithPrice>} />
              <Route path='/helper/vtho/contracts/:address' element={<WrapContentWithPrice><VthoContracts /></WrapContentWithPrice>} />
              <Route path='/helper/vtho/calculator' element={<WrapContent><VthoCalculator /></WrapContent>} />
              <Route path='/helper/wizard' element={<WrapContent><HelperWizard /></WrapContent>} />
              <Route path='/imprint' element={<WrapContent><Imprint /></WrapContent>} />
              <Route path='/privacy' element={<WrapContent><Privacy /></WrapContent>} />
              <Route path='/inspect/*' element={<WrapContent><Inspect /></WrapContent>} />
              <Route path='*' element={<Home />} />
            </Routes>
          </Suspense>
        </Col>

        <Col span={24}>
          <FooterBig />
        </Col>

      </Row>

      <Drawer placement='right' closable={false} open={displayDrawer} bodyStyle={{ padding: 0 }} maskClosable onClose={hideDrawer}>
        <Suspense fallback={<LoadingLogo message='Loading Menu …' style={{ marginTop: 64 }} />}>
          <Menu logo />
        </Suspense>
      </Drawer>

    </ApolloProvider>
  )
}

function WrapContent ({ children }) {
  return (
    <Row gutter={0}>
      <Col span={22} offset={1}>
        <div
          style={{
            marginTop: -720,
            minHeight: 720,
            backgroundColor: '#fff',
            borderRadius: 8,
            padding: 32
          }}
          className='outer-shadow'
        >
          {children}
        </div>
      </Col>
    </Row>
  )
}

function WrapContentWithPrice ({ children }) {
  return (
    <ProvideVexchange>
      <WrapContent>{children}</WrapContent>
    </ProvideVexchange>
  )
}

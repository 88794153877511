import { useContext, createContext, useState, useEffect, useCallback } from 'react'
import useFetch from 'use-http'

const vexchangeContext = createContext()

export function ProvideVexchange ({ children }) {
  const provides = useProvideVexchange()
  return <vexchangeContext.Provider value={provides}>{children}</vexchangeContext.Provider>
}

export const useVexchange = () => {
  return useContext(vexchangeContext)
}

function useProvideVexchange () {
  const [vtho, setVtho] = useState(0)

  const { get: getTokenPrice } = useFetch('https://api.vexchange.io/v1/tokens')

  const updateVtho = useCallback(async () => {
    try {
      const { usdPrice } = await getTokenPrice('/0x0000000000000000000000000000456E65726779')
      setVtho(usdPrice)
    } catch (err) {
      console.error(err)
      setVtho(0)
    }
  }, [getTokenPrice])

  useEffect(() => { updateVtho() }, [updateVtho])

  return {
    vtho
  }
}

import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Steps, Tabs } from 'antd'
import ImageShadow from 'react-image-shadow'
import styled from 'styled-components'
import { useInterval } from '../../hooks/useInterval'
import Mermaid from '../../common/Mermaid'

import HomeFeature from './Feature'

const { Paragraph, Title, Text } = Typography

const TabTitle = styled(Text)`
  font-weight: 500;
  font-size: 0.8rem;
`

const tabList = [
  {
    type: 'feature',
    label: <TabTitle>REST-API</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Simple API access and Legacy Application Support</Title>
            <Paragraph type='secondary'>
              Our services make it easy to read data or browse information on the blockchain like a regular database.
            </Paragraph>
            <Paragraph type='secondary'>
              You can write data and execute transactions without worrying about wallets or new dependencies
            </Paragraph>
            <Paragraph type='secondary'>
              Legacy applications that support HTTP can even send data to your blockchain contracts or read from them (e.g. IFTTT).
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'Transaction API', description: 'to submit transactions using a POST request, fees paid by Fee Delegation' },
                { title: 'Call API', description: 'to read data in a user friendly clear text format' },
                { title: 'Event API', description: 'to browse and filter information like a database' },
                { title: 'Developer Comfort', description: 'using established standards and human readable APIs' }

              ]}
            />
          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/API.webp')}
              width='100%'
              alt='REST-APIs'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>Circular Communication</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Connect Web-APIs with Blockchain Events</Title>
            <Paragraph type='secondary'>
              Our Webhooks allow you to listen for blockchain events and receive HTTP requests on your web API.
            </Paragraph>
            <Paragraph type='secondary'>
              Webhooks make it easy to implement off-chain processes that are triggered by contracts. You can even build a complete processing circle by sending data from the blockchain to your backend using Webhooks, and responding with the Transaction API.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'EventListener', description: 'to intercept Blockchain events from any contract' },
                { title: 'Webhook', description: 'to call any API to run custom processes' },
                { title: 'Connect', description: 'Blockchain back to your web applications' }
              ]}
            />

          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Webhooks.webp')}
              width='100%'
              alt='Webhook'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>Automation with Schedulers</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Automation with Schedulers</Title>
            <Paragraph type='secondary'>
              Execute transactions periodically with a cronjob-like scheduler.
            </Paragraph>
            <Paragraph type='secondary'>
              You can use it to trigger regular data updates, archiving jobs, or payouts in your contracts.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                {
                  title: 'Interval',
                  description: 'starting from a minute to a fixed timestamp'
                },
                {
                  title: 'Transaction',
                  description: 'is broadcast periodically'
                }
              ]}
            />
          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Schedulers.webp')}
              width='100%'
              alt='Schedulers'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>Indexing-Service</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Blockchain Indexer</Title>
            <Paragraph type='secondary'>
              Access blockchain information in a fast and reliable way with a single query.
            </Paragraph>

            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'NFT Ownerships', description: 'a list of all tokens owned by a wallet' },
                { title: 'NFT Owners', description: 'a list of all owners (including tokens) for an NFT contract' },
                { title: 'NFT Transfers', description: 'a history of past activity for an NFT' },
                { title: 'Historical Data', description: 'access to information at a certain point in the past or within a specific time period.' }
              ]}
            />

            <Paragraph type='secondary'>
              Browse in the <Link to='/docs/indexer/examples'>examples</Link> to learn more about the functionality.
            </Paragraph>

          </>
        }
        teaser={
          <Mermaid
            code={`
    C4Dynamic
    Boundary(Development, "Development", "") {
        Person(Developer, "Developer", "Developer using vechain.energy")
    }
    
    Boundary(Usage, "Production Use", "dApp") {
        System(Backend, "Backend", "dApp Backend")
        Person(User, "User", "A user of an application")
    }
    
    Boundary(Indexer, "Indexer", "index.api.vechain.energy") {
        Boundary(Collection, "Data Collection", "") {
            System(Blockchain, "Blockchain", "vechain Network")
            System(Webhook, "Webhook", "vechain.energy webhook")
        }
    
        Container(GraphQL, "GraphQL API")
        ContainerDb(Database, "Storage")
    }
    
    Rel(Blockchain, Webhook, "send NFT-Transfer")
    Rel(Webhook, Database, "foward NFT-Information")
    Rel(GraphQL, Database, "query NFT", "filtered & grouped")
    
    Rel(Developer, GraphQL, "test queries", "API-Key protected")
    BiRel(User, Backend, "Uses", "developer provided backend")
    Rel(Backend, GraphQL, "access NFT information", "API-Key protected")
    
    
    
    
    UpdateLayoutConfig($c4ShapeInRow="3", $c4BoundaryInRow="2")
    UpdateRelStyle(Blockchain, Webhook, $offsetX="-50", $offsetY="50")
    UpdateRelStyle(Developer, GraphQL, $offsetX="20", $offsetY="-50")
    UpdateRelStyle(GraphQL, Database, $offsetX="-40", $offsetY="40")
    UpdateRelStyle(Backend, GraphQL, $offsetY="0", $offsetX="60")
    UpdateRelStyle(Webhook, Database, $offsetX="15", $offsetY="-20")
    UpdateRelStyle(User, Backend, $offsetX="-40", $offsetY="70")
    
    
`}
          />
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>OAuth 2 & OpenID Connect for Wallets</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Support Blockchain-Natives with well known Auth-Services</Title>
            <Paragraph type='secondary'>
              Our OAuth 2 & OpenID Connect service allows you to easily identify users who are familiar with the native wallet software Sync2.
            </Paragraph>
            <Paragraph type='secondary'>
              Many applications (e.g. Wordpress) support authentication configurations and can implement blockchain identification.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                {
                  title: 'Configure',
                  description: 'in any supporting application'
                },
                {
                  title: 'Identify',
                  description: 'without touching web3-libraries'
                },
                {
                  title: 'Profiles',
                  description: 'can be managed by the User on the Blockchain'
                }
              ]}
            />

          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Auth.webp')}
              width='100%'
              alt='OAuth 2 & OpenID Connect'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>Fee Delegation</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Fee Delegation with simple administration</Title>
            <Paragraph type='secondary'>
              Fee delegation enables gasless transactions, allowing you to use blockchain applications like regular apps. <br />
            </Paragraph>
            <Paragraph type='secondary'>
              You can whitelist origins or recipient addresses to pay for their gas fees, or implement your own logic in your own contract if you need more control.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'Whitelist multiple Origins', description: 'to pay for transactions from specific wallets or internal implementation (Node-Services, Development-Environment, etc.)' },
                { title: 'Whitelist multiple Recipients', description: 'to pay for all interactions with a contract or wallet' },
                { title: 'Configure contract for extended Logic', description: 'to support complex logics (only NFT-Token-Holders, Users with Roles, etc.)' },
                { title: 'Add Metadata', description: 'to identify wallets by name and decode transactions with ABI' }
              ]}

            />
          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Whitelist.webp')}
              width='100%'
              alt='Whitelist'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>Transaction Analytics</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Transaction-Details</Title>
            <Paragraph type='secondary'>
              You can easily browse through your function calls. You can learn how much they cost, who is using them, and export them for more detailed analysis.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'Text search on all transactions' },
                { title: 'Filter by time range' },
                { title: 'Find errors or uncommited transactions' },
                { title: 'Identify expensive transactions' },
                { title: 'Detect Wallets and Origins' },
                { title: 'Export for further local analysis' }
              ]}
            />
          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Event.webp')}
              width='100%'
              alt='Event'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'feature',
    label: <TabTitle>Historical Performance</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Historical Performance</Title>
            <Paragraph type='secondary'>
              See all your VTHO consumption and costs in one place.
            </Paragraph>
            <Paragraph type='secondary'>
              A daily average gives you an estimated view of your costs, and helps you calculate future costs in a way that's similar to hosting costs.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'Historical VTHO Consumption' },
                { title: 'Fiat conversion for business calculations' },
                { title: 'Estimate how long your VTHO balance will last' },
                { title: 'Identify spikes and abnormal use' }
              ]}
            />
          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Dashboard.webp')}
              width='100%'
              alt='Dashboard'
              shadowHover
            />
          </>
        }
      />
    )
  },
  {
    type: 'delegation',
    label: <TabTitle>Balance Handling</TabTitle>,
    children: (
      <HomeFeature
        bullets={
          <>
            <Title level={4}>Wallet Deposits and Balance Handling</Title>
            <Paragraph type='secondary'>
              Stop worrying about managing the VTHO balance for Fee Delegation.
            </Paragraph>
            <Paragraph type='secondary'>
              You can automatically deposit from an external wallet, or receive push notifications if your wallet's balance is low.
            </Paragraph>
            <Steps
              progressDot
              current={99}
              direction='vertical'
              items={[
                { title: 'Auto-Deposit', description: 'from external wallets when balance becomes low' },
                { title: 'Notifications', description: 'by email if balance becomes critical' }
              ]}
            />
          </>
        }
        teaser={
          <>
            <ImageShadow
              src={require('./assets/Balance.webp')}
              width='100%'
              alt='Balance'
              shadowHover
            />
          </>
        }
      />
    )
  }
]

export default function HomeTabs ({ type }) {
  const [activeTab, setActiveTab] = useState(1)
  const [interval, setInterval] = useState(5 * 1000)

  const items = tabList.filter(tab => tab.type === type)

  const handleTabChange = (key) => {
    setInterval(300 * 1000)
    setActiveTab(key)
  }

  useInterval(() => {
    // const nextKey = activeTab === items.length ? 1 : activeTab + 1
    // setActiveTab(nextKey)
  }, interval)

  return (
    <Tabs
      animated
      centered
      style={{ maxWidth: 'calc(100vw  - 16px)' }}
      type='line'
      size='large'
      activeKey={activeTab}
      onChange={handleTabChange}
      items={items.map((tab, index) => ({ ...tab, key: index + 1 }))}
    />

  )
}

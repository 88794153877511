import { useState, useCallback } from 'react'
import { Typography, Row, Col, Grid, Button } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Texty from 'rc-texty'
import 'rc-texty/assets/index.css'

import { UserOutlined as IconWallet } from '@ant-design/icons'
import Logo from './Logo'

const { useBreakpoint } = Grid
const { Paragraph, Text, Link: LinkExt } = Typography

const CoverText = styled(Text)`
  color: #fff;
`

const CoverParagraph = styled(Paragraph)`
  color: #fff;
`

const CoverTitle = styled(CoverParagraph)`
  font-size: 2rem;
`

const EnergyPrimary = styled.div`
  font-size: 2rem;
  color: #F15421;
  height: 52px;
  div {
    background: -webkit-linear-gradient(#ec1c24, #f6921e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const PrimaryText = styled(Text)`
  font-size: 2rem;
  color: #fff;
`

const animatedTitles = [
  'Vechain-Developers',
  'Web2-Developers',
  'Web3-Developers',
  'Project-Managers'
]

export default function HeaderBig (): React.ReactElement {
  const screens = useBreakpoint()
  const [animatedTextIndex, setAnimatedTextIndex] = useState<number>(0)
  const [showAnimatedText, setShowAnimatedText] = useState<boolean>(true)

  const toggleAnimation = useCallback(() => {
    const timer = setTimeout(() => {
      if (showAnimatedText) {
        return setShowAnimatedText(false)
      }

      if (animatedTextIndex >= (animatedTitles.length - 1)) {
        setAnimatedTextIndex(0)
      } else {
        setAnimatedTextIndex(animatedTextIndex + 1)
      }
      setShowAnimatedText(true)
    }, (showAnimatedText ? 10 : 1) * 1000)

    return () => clearTimeout(timer)
  }, [animatedTextIndex, showAnimatedText])

  return (
    <Row
      gutter={0}
      align='middle'
      style={{
        backgroundImage: 'linear-gradient(30deg, #0476C2 0%, #0476C2 10%, #0F1F6E 50%, #101161 100%)',
        minHeight: 720
      }}
    >
      <Col
        span={24}
        style={{
          backgroundImage: `url(${require('./Hero.webp')})`,
          backgroundPosition: screens.sm ? '100% 100%' : '50% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: screens.sm ? screens.xxl ? '800px' : '50%' : 'contain',
          minHeight: 720
        }}

      >
        <div style={{
          backgroundImage:
            screens.sm
              ? 'linear-gradient(25deg, #fff 0%, #fff 10%, transparent 10%)'
              : 'linear-gradient(25deg, #fff 0%, #fff 10%, rgba(0,0,0,0.1) 10%)'
        }}
        >
          <div style={{ padding: 24 }}>
            <Row
              gutter={0}
            >
              <Col span={8} style={{ height: 72, backgroundColor: 'transparent' }}>
                <Link to='/'><Logo height={48} /></Link>
              </Col>
              <Col xs={0} lg={15} style={{ textAlign: 'right', paddingTop: 8 }}>
                {/* <Styles>
                  <MainMenuLinkInt to='/docs/what-is-fee-delegation'>What is Fee Delegation?</MainMenuLinkInt>
                  <MainMenuLinkInt to='/helper'>Tools</MainMenuLinkInt>
                  <MainMenuLinkInt to='/docs'>Client Examples</MainMenuLinkInt>
                  <MainMenuLinkExt href='https://sandbox.vechain.energy' target='_blank' rel='noreferrer'>Sandbox</MainMenuLinkExt>
                </Styles> */}
              </Col>
              <Col xs={16} lg={1} style={{ textAlign: 'right' }}>
                {/* <Styles>
                  <Button icon={<IconMenu />} type='link' size='large' onClick={handleShowDrawer} />
                </Styles> */}
              </Col>
            </Row>
          </div>

          <Row
            gutter={0}
            style={{
              minHeight: screens.sm ? 640 : 720
            }}
          >
            <Col lg={11} md={14} xs={22} offset={1}>
              <Row gutter={[0, 16]} style={{ marginTop: screens.lg ? 128 : 64 }}>
                <Col span={24}>
                  <CoverTitle level={2}>
                    <PrimaryText>Blockchain Services for</PrimaryText><br />
                    <EnergyPrimary>
                      <Texty type='alpha' mode='smooth' onEnd={toggleAnimation}>{showAnimatedText && animatedTitles[animatedTextIndex]}</Texty>
                    </EnergyPrimary>
                  </CoverTitle>
                </Col>

                <Col span={24}>
                  <CoverText>
                    Implement Blockchain into your application without the crypto attachments
                  </CoverText>
                </Col>

                <Col span={24}>

                  <LinkExt href='https://app.vechain.energy/?instantSignIn=true'><Button style={{ minWidth: 480 }} type='primary' icon={<IconWallet />} size={screens.sm ? 'middle' : 'large'}>Sign in now</Button></LinkExt>
                </Col>

              </Row>

            </Col>

          </Row>
        </div>
      </Col>

    </Row>

  )
}

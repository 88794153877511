import { Row, Col, Grid, Typography } from 'antd'
import styled from 'styled-components'
import UseCases from '../UseCases'

const { Title } = Typography
const { useBreakpoint } = Grid

const WhiteTitle = styled(Title)`
  color: #fff !important;
`

const Styles = styled.div`
  *:not(.ant-tag) {
    color: #fff !important;
    line-height: 1.75rem;
  }
`

export default function Features (): React.ReactElement {
  const screens = useBreakpoint()
  const cornerDistance = screens.md ? 10 : 5

  return (
    <Row
      gutter={0}
      align='middle'
      style={{
        backgroundImage: 'linear-gradient(30deg, #101260 0%, #0857A2 70%)'
      }}
    >
      <Col
        span={24}
        style={{
          backgroundImage: screens.lg ? 'url(/dots.svg)' : '',
          backgroundPosition: '100% calc(100% + 80px)',
          backgroundRepeat: 'no-repeat'
        }}

      >

        <div style={{
          backgroundImage: `linear-gradient(25deg, transparent ${100 - cornerDistance}%, #fff 0%, #fff 0%)`
        }}
        >

          <div style={{
            backgroundImage: `linear-gradient(25deg, #fff 0%, #fff ${cornerDistance}%, transparent ${cornerDistance}%)`
          }}
          >

            <Row gutter={[0, 0]}>
              <Col span={22} offset={1} style={{ textAlign: 'left', paddingTop: 32 }}>
                <WhiteTitle>Potential Use Cases</WhiteTitle>
              </Col>

              <Col span={22} offset={1} className='ven-benefits' style={{ marginBottom: screens.xs ? 32 : 128, minHeight: screens.md ? 600 : 'fit-content' }}>
                <Styles>
                  <UseCases />
                </Styles>
              </Col>
              <Col span={24} />
            </Row>
          </div>
        </div>
      </Col>

    </Row>
  )
}

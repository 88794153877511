import { Row, Col, Typography, Divider, Button, Grid } from 'antd'
import styled from 'styled-components'
const { Title, Paragraph, Link: LinkExt } = Typography
const { useBreakpoint } = Grid

const TitleHighlight = styled.span`
  color: #353395;
  font-size: inherit;
`
const TitleHighlight2 = styled.span`
  color: #FF6D3D;
  font-size: inherit;
`

export default function SignInButtons (): React.ReactElement {
  const screens = useBreakpoint()

  return (
    <Row gutter={[16, 64]}>
      <Col xs={0} lg={24} className='text-center' />
      <Col xs={0} lg={24} className='text-center'>
        <Divider />
      </Col>
      <Col xs={24} xxl={12} className='text-left'>
        <Title level={2}><TitleHighlight2>Are you ready to </TitleHighlight2> <TitleHighlight>unleash your inner superhero?</TitleHighlight></Title>
        <Paragraph>
          Our resources can help you overcome obstacles that are holding you back.<br />
        </Paragraph>
        <Paragraph>
          From <LinkExt href='https://learn.vechain.energy/' target='_blank' rel='noreferrer noopener'>documentation and code snippets</LinkExt> to <LinkExt href='https://gitlab.com/vechain.energy/examples' target='_blank' rel='noreferrer noopener'>example projects</LinkExt> and <LinkExt href='https://blog.vechain.energy' target='_blank' rel='noreferrer noopener'>tutorials</LinkExt> we have everything you need to develop your powers.<br />
          If you need extra support, our <LinkExt href='https://discord.gg/dhVCVNbHRT' target='_blank' rel='noreferrer noopener'>developer community on Discord</LinkExt> is here to help.<br />
        </Paragraph>
        <Paragraph>
          <LinkExt href='https://discord.gg/dhVCVNbHRT' target='_blank' rel='noreferrer noopener'><Button type='primary' block size={!screens.sm ? 'large' : null}>Join us now{screens.md ? ' and let\'s solve any challenges together!' : '!'}</Button></LinkExt>
        </Paragraph>
      </Col>
      <Col xs={0} xxl={12} />
      <Col span={24} className='text-center' />
    </Row>
  )
}

import { createRoot } from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import 'swagger-ui/dist/swagger-ui.css'
import 'react-js-cron/dist/styles.css'
import 'react-image-shadow/assets/index.css'
import { ConfigProvider } from 'antd'
import { Styles, light } from './Layout/Themes'
import './index.css'

function noop () { return undefined }

if (process.env.NODE_ENV !== 'development') {
  console.log = noop
  console.warn = noop
  console.error = noop
}

const NETWORK_TYPE = process.env.REACT_APP_CONNEX_NETWORK_TYPE

const container = document.getElementById('app')
if (container !== null) {
  const root = createRoot(container)
  root.render(
    <div className={[`ven-network-${NETWORK_TYPE}`].join(' ')}>
      <ConfigProvider theme={light}>
        <Styles>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Styles>
      </ConfigProvider>
    </div>
  )
}
